'use strict';

var SiteConstants = require('constants/SiteConstants');
var updateMiniCart = true;
var headerUtils = require('core/utilities/headerUtils');
var $modalBackground = $('.modal-background');

function setMiniCartBodyMaxHeight(minicart) {
    if (minicart) {
        var $minicart = $(minicart);
        $minicart.find('.product-summary').css('height', $minicart.outerHeight() - $minicart.find('.minicart-footer.container').outerHeight() - (SiteConstants.Spacer * 2))
    }
}

function loadMiniCart() {

    if ($('#sg-navbar-collapse').hasClass('in')) {
        $('button.navbar-toggler').click();
    }

    var url = $('.minicart').data('action-url');
    var count = parseInt($('.minicart .minicart-quantity').text(), 10);

    if (count !== 0 && $('.minicart .popover.show').length === 0) {
        var $header = $('header');
        var $headerNav = $header.find('.header-nav');
        if (!updateMiniCart && !$('.minicart .popover').is(':empty')) {
            setTimeout(() => {
                $('html').scrollTop($headerNav.offset().top);
                var headerNavHeight = headerUtils.getHeaderHeight();
                $modalBackground
                    .fadeIn(SiteConstants.TransitionSpeed)
                    .css('top', headerNavHeight);
                $('html').addClass('lock-scroll');
                $('.minicart .popover').addClass('show');
            }, SiteConstants.TransitionSpeed);
            return;
        }

        setTimeout(() => {
            $('.minicart .popover').addClass('show');
            $('.minicart .popover').spinner().start();
            $('html').scrollTop($headerNav.offset().top);
            var headerNavHeight = headerUtils.getHeaderHeight();
            $modalBackground
                .fadeIn(SiteConstants.TransitionSpeed)
                .css('top', headerNavHeight);
            $('html').addClass('lock-scroll');
            $.get(url, data => {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                updateMiniCart = false;
                $.spinner().stop();
                $('body').trigger('minicart:loaded', $('.minicart .popover'));
            });
        }, SiteConstants.TransitionSpeed);
    }
}

module.exports = function () {
    $('.minicart').on('count:update', (event, count) => {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.minicart').on('click touchstart', (e) => {
        var target = $(e.target);
        if (target.hasClass('minicart') || target.hasClass('minicart-link') || target.hasClass('minicart-quantity') || target.hasClass('minicart-icon')) {
            e.stopPropagation();
            e.preventDefault();
        } else {
            return;
        }

        loadMiniCart();
    });

    $('body').on('touchstart click', event => {
        if ($('.minicart').has(event.target).length <= 0) {
            if ($('.minicart .popover').hasClass('show')) {
                $('.minicart .popover').removeClass('show');
                if (event && event.target && $(event.target).hasClass('navbar-toggler')) {
                    return;
                }
                $modalBackground
                    .fadeOut(SiteConstants.TransitionSpeed);
                $('html').removeClass('lock-scroll');
            }
        }
    });

    $('body').on('click', '.js-minicart-close', event => {
        event.preventDefault();
        $modalBackground
            .fadeOut(SiteConstants.TransitionSpeed);
        $('html').removeClass('lock-scroll');
        $('.minicart .popover').removeClass('show');
    });

    $('body').on('change', '.minicart .quantity', event => {
        if ($(event.target).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    $('body').on('product:afterAddToCart cart:update', (e, data) => {
        updateMiniCart = true;
        if (data && data.action && data.action === 'CommercePaymentsCheckoutServices-GetCart') {
            return;
        }
        loadMiniCart();
    });

    $('body').on('minicart:loaded', (e, minicart) => {
        setMiniCartBodyMaxHeight(minicart);
        var target = $(minicart);
        $('body').trigger('targetslider:ready', target.find('.experience-commerce_layouts-sliderCategory'));
        $(document).triggerHandler('load.einstein');
    });
};
