
var main = require('integrations/main');

main.baseFiles.collapsibleItem = require('./components/collapsibleItem');
main.baseFiles.miniCart = require('./components/miniCart');
main.baseFiles.base = require('./product/base');

var siteIntegrations = require('integrations/integrations/siteIntegrationsUtils');
var toggleObject = siteIntegrations.getIntegrationSettings();

$('body').on('quantityStepper:change', (event, stepper) => {
    var cancelContainer = $('.js-cancel-container');
    if (cancelContainer && cancelContainer.length >0) {
        var globalCancelButton = $('.js-cancel-button');
        var cancelqty = 0;
        cancelContainer.each(function(i, el){
            var localStepper = $(el).find('.quantity-stepper input');
            if (localStepper && localStepper.length === 1) {
                cancelqty += Number(localStepper.val()) || 0;
            }
        })
        if (cancelqty > 0) {
            globalCancelButton.attr('disabled', false);
            globalCancelButton.removeClass('d-none');
        } else {
            globalCancelButton.attr('disabled', true);
            globalCancelButton.addClass('d-none');
        }
    }

    var returnContainer = $('.js-return-container');
    if (returnContainer && returnContainer.length) {
        var globalReturnButton = $('.js-return-button');
        var returnqty = 0;
        returnContainer.each(function(i, el){
            var localStepper = $(el).find('.quantity-stepper input');
            if (localStepper && localStepper.length === 1) {
                var container = $(localStepper).parents('.js-return-container').find('.js-return-reason-container');
                var qtyToAdd = Number(localStepper.val()) || 0;
                returnqty += qtyToAdd;
                if (qtyToAdd > 0) {
                    container.removeClass('d-none');
                } else {
                    container.addClass('d-none');
                }
            }
        })

        if (returnqty > 0) {
            globalReturnButton.attr('disabled', false);
            globalReturnButton.removeClass('d-none');
        } else {
            globalReturnButton.attr('disabled', true);
            globalReturnButton.addClass('d-none');
        }
    }
});

$('body').on('click', '.js-cancel-button', function(e) {
    var target = $(e.target);
    $('.card.order-product-summary').spinner().start();
    var cancelData = {
        orderNumber: '',
        lineItems: []
    };
    $('.js-cancel-container').each(function (i, el){
        cancelData.orderNumber = $(el).data('ordernumber');
        cancelData.lineItems.push({
            lineItemUUID: $(el).data('lineitemuuid'),
            quantity: $(el).find('.quantity-stepper input').val()
        });
    })
    $.ajax({
        url: target.data('url'),
        type: 'post',
        data: {
            cancelData: JSON.stringify(cancelData)
        },
        success: function(data) {
            $('.card.order-product-summary').spinner().stop();
            if (data.success) {
                $('.js-reload-ordertrack').submit();
            } else {
                $('.js-cancel-return-error').html(data.msg);
                $('.js-cancel-return-error').parent().attr('hidden', false);
            }
        },
        error: function(err) {
            target.parent().spinner().stop();
            console.log(err);
        }
    })
})

$('body').on('click', '.js-return-button', function(e) {
    var target = $(e.target);
    $('.card.order-product-summary').spinner().start();
    var returnData = {
        orderNumber: '',
        lineItems: []
    };
    $('.js-return-container').each(function (i, el){
        returnData.orderNumber = $(el).data('ordernumber');
        returnData.lineItems.push({
            lineItemUUID: $(el).data('lineitemuuid'),
            quantity: $(el).find('.quantity-stepper input').val(),
            returnReason: $(el).find('.js-return-reason').val()
        });
    })
    $.ajax({
        url: target.data('url'),
        type: 'post',
        data: {
            returnData: JSON.stringify(returnData)
        },
        success: function(data) {
            $('.card.order-product-summary').spinner().stop();
            if (data.success) {
                $('.js-reload-ordertrack').submit();
            } else {
                $('.js-cancel-return-error').html(data.msg);
                $('.js-cancel-return-error').parent().attr('hidden', false);
            }
        },
        error: function(err) {
            $('.card.order-product-summary').spinner().stop();
            console.log(err);
        }
    })
})

if (toggleObject.turntoCartridgeEnabled) {
    main.baseFiles.turnto = {
        teasers: require('./integrations/turnto/teasers'),
        teasersproductgrid: require('./integrations/turnto/teasersproductgrid')
     };
    // on cart page, update selected product for turnto cartridge
    $('[data-action="Cart-Show"]').length > 0
        && (main.baseFiles.turnto.updateTurntoSelectedProduct = require('./integrations/turnto/product/quickView'));
}

module.exports = main;
