'use strict';

var baseCore = require('integrations/product/base');
var siteIntegrations = require('integrations/integrations/siteIntegrationsUtils');
var toggleObject = siteIntegrations.getIntegrationSettings();

function handlePostCartAdd(response) {
    // conditional added for response, sometimes it was failing when called on page load
    if (response) {
        $('.minicart').trigger('count:update', response);
        var messageType = response.error ? 'alert-danger' : 'alert-success';
        // show add to cart toast
        if (response.newBonusDiscountLineItem
            && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
            this.chooseBonusProducts(response.newBonusDiscountLineItem);
        } else if (messageType === 'alert-danger') {
            if ($('.add-to-cart-messages').length === 0) {
                $('body').append(
                    '<div class="add-to-cart-messages"></div>'
                );
            }

            $('.add-to-cart-messages').append(
                '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
                + response.message
                + '</div>'
            );

            setTimeout(function () {
                $('.add-to-basket-alert').remove();
            }, 5000);
        }
    }
}
function createSlider(images, assets, $productContainer) {
    var $sliderContainers = $productContainer.find('.slider-container');
    var data = images !== null ? {images} : null;
    data.assets = assets || null;

    // Reversing order in which to update sliders so that thumbnails get initialized first
    $($sliderContainers.get().reverse()).each((index, sliderContainer) => {
        if (! $(sliderContainer).hasClass('complete-the-look')) {
            var $slider = $(sliderContainer).find('.slider');
            $slider.trigger('slider:update', data);
        }
    });
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    if (attr.displayValue) {
        $productContainer.find('[data-attr="' + attr.id + '"]').find('.non-color-display-value, .color-display-value')
            .text(attr.displayValue);
    }

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]');

        var $swatchButton = $attrValue.parent('button');

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
            $attrValue.attr('selected', 'selected');
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
            $attrValue.removeAttr('selected');
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable available unavailable out-of-stock');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
        $attrValue.addClass(attrValue.available ? 'available' :  toggleObject.viewOutOfStockItems ? 'out-of-stock' : 'unavailable');

        $attrValue.attr('value', attrValue.url).removeAttr('disabled');
        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

baseCore.methods.handlePostCartAdd = handlePostCartAdd;
baseCore.methods.createSlider = createSlider;
baseCore.methods.processSwatchValues = processSwatchValues;

baseCore.nonColorAttribute = function () {
    var scope = this;

    $(document).on('click', 'button.swatch:not(.color-attribute)', function (e) {
        e.preventDefault();

        $('body').trigger('product:swatchClicked', [$(this), toggleObject]); // add trigger for any attribute click use (incl. nonClickable Attrs)
        if (scope.methods.checkForClickableAttribute($(this)) && !toggleObject.viewOutOfStockItems) {
            return;
        }

        if ($('.product-bundle').length) {
            var $productContainer = $('.bundle-main-product').length ? $(this).closest('.bundle-main-product') : $(this).closest('.product-bundle');
        } else {
            var $productContainer = $(this).closest('.set-item');
        }

        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }

        scope.methods.attributeSelect($(this).attr('data-url'), $productContainer);

        $(this).closest('.non-color-attribute-swatches').find('.non-color-display-value').text($(this).find('.swatch-value').data('display-value'))
            .removeClass('text--brand-tertiary')
            .addClass('text--brand-secondary');
    });
};

//Attributes that display as color swatches
baseCore.colorAttribute = function () {
    var scope = this;

    $(document).on('click', '[data-attr="color"] button', function (e) {
        e.preventDefault();

        $('body').trigger('product:swatchClicked', [$(this), toggleObject]); // add trigger for any attribute click use (incl. nonClickable Attrs)
        if (scope.methods.checkForClickableAttribute($(this)) && !toggleObject.viewOutOfStockItems) {
            return;
        }

        if ($('.product-bundle').length) {
            var $productContainer = $('.bundle-main-product').length ? $(this).closest('.bundle-main-product') : $(this).closest('.product-bundle');
        } else {
            var $productContainer = $(this).closest('.set-item');
        }

        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }
        scope.methods.attributeSelect($(this).attr('data-url'), $productContainer);

        $productContainer.find('.color-display-value').text($(this).find('.swatch').data('displayvalue'))
            .removeClass('text--brand-tertiary')
            .addClass('text--brand-secondary');
    });
};
module.exports = baseCore;
