'use strict';

module.exports = {
    updateTurntoSelectedProduct: function() {
        // copied from cart.js, listening on variation change on quickview modal
        ('body').on('product:afterAttributeSelect', function (e, response) {
            // eslint-disable-next-line no-undef
            TurnToCmd('set', { sku: response.data.product.id }); // eslint-disable-line new-cap
        });
    }
};
